import React from 'react';
import { Helmet } from 'react-helmet';

import SEO from 'sections/Layout/SEO';

import Dunkin from 'mocks/webflow/dunkin';

import 'styles/webflow/normalize.scss';
import 'styles/webflow/webflow.scss';
import 'styles/webflow/dunkindonuts-survey.webflow.scss';

const seo = {
  title: 'Dunkindonuts Survey | Survey Promo | Screver',
  metaDesc:
    'Look at the Dunkindonuts website with our Screver Survey. It allows you to attract, collect, analyze in real-time, and implement data-driven actions.',
  opengraphUrl: 'https://screver.com/cases/cx/dunkin/',
};

const DunkinPage = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">{`!function(o,c){var n=c.documentElement,t=" w-mod-";n.className+=t+"js",("ontouchstart"in o||o.DocumentTouch&&c instanceof DocumentTouch)&&(n.className+=t+"touch")}(window,document);`}</script>
      </Helmet>

      <SEO seo={seo} themeColor="#ff6e0c" />

      <div
        className="webflow webflow-dunkin"
        dangerouslySetInnerHTML={{ __html: Dunkin }}
      ></div>
    </>
  );
};

export default DunkinPage;
