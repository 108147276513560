const DUNKIN = `
<!--  Last Published: Wed Oct 19 2022 15:05:41 GMT+0000 (Coordinated Universal Time)  -->
<body class="body">
  <p class="paragraph">Bestellen Sie Ihre Donuts vor 11 Uhr, um diese morgen zu erhalten!</p>
  <div data-animation="default" data-collapse="medium" data-duration="400" data-easing="ease" data-easing2="ease" role="banner" class="navbar w-nav">
    <div class="container w-container">
      <div class="div-block-2">
        <a href="#" class="brand w-nav-brand"><img src="/images/webflow/dunkin-logo.svg" loading="eager" width="180" alt=""></a>
        <nav role="navigation" class="nav-menu w-nav-menu">
          <a href="#" data-w-id="64aa346a-3ac1-bfc9-934c-208f6061e18b" class="nav-link w-nav-link">STOREFINDER</a>
          <a href="#" data-w-id="64aa346a-3ac1-bfc9-934c-208f6061e18d" class="nav-link-2 w-nav-link">JOBS</a>
          <a href="#" data-w-id="64aa346a-3ac1-bfc9-934c-208f6061e18f" class="nav-link-3 w-nav-link">BLOG</a>
          <div data-hover="false" data-delay="0" class="dropdown w-dropdown">
            <div class="dropdown-toggle w-dropdown-toggle">
              <div class="icon w-icon-dropdown-toggle"></div>
              <div>MEHR</div>
            </div>
            <nav class="dropdown-list w-dropdown-list">
              <a href="#" data-w-id="71ff848a-07ab-aee1-96bd-7b6483620a7a" class="dropdown-link w-dropdown-link">über uns</a>
              <a href="#" data-w-id="71ff848a-07ab-aee1-96bd-7b6483620a7c" class="dropdown-link-2 w-dropdown-link">Kontakt</a>
              <a href="#" data-w-id="71ff848a-07ab-aee1-96bd-7b6483620a7e" class="dropdown-link-3 w-dropdown-link">Donuts nach wunsch</a>
            </nav>
          </div>
        </nav>
        <div data-hover="true" data-delay="0" class="dropdown-2 w-dropdown">
          <div class="dropdown-toggle-3 w-dropdown-toggle"><img src="/images/webflow/de-flag.png" loading="lazy" srcset="/images/webflow/de-flag-p-500.png 500w, /images/webflow/de-flag-p-800.png 800w, /images/webflow/de-flag-p-1080.png 1080w, /images/webflow/de-flag.png 2560w" width="16" sizes="(max-width: 767px) 16px, (max-width: 991px) 2vw, (max-width: 1279px) 16px, 1vw" alt=""></div>
          <nav class="dropdown-list-3 w-dropdown-list"><img src="/images/webflow/fr-flag.svg" loading="lazy" width="16" alt="" class="image-4"></nav>
        </div>
        <div class="menu-button w-nav-button">
          <div class="w-icon-nav-menu"></div>
        </div>
        <a data-w-id="3ec1f44f-3ccf-2f77-0787-389a0b4d723d" href="#" class="button-btn w-button">ORDER</a>
      </div>
    </div>
  </div>
  <div class="w-embed w-script">
    <script type="text/javascript" src="https://go.screver.com/scripts/embed-survey?s=62fd0e4fb275d064a3a88e2a&t=popover&c=capptoo&btnL=Pass the survey&i=bulb&sp=right&w=100%&h=800px&hH=true&hF=true"></script>
    <style>
	.w-webflow-badge {
  	opacity: 0 !important;
    pointer-events: none;
  }
  .popover-container {
    width: 660px !important;
    height: 460px !important;
  	border-radius: 24px;
    transform: translate3d(0,0,0);
    z-index: 10;
    right: 30px;
    bottom: 100px;
    position: fixed !important;
  }
  .button {
  	background: #ff6e0c !important;
    z-index: 10;
    position: fixed;
  }
  @media (max-width: 1000px) {
    .popover-container {
      width: calc(100% - 60px) !important;
      margin-left: auto;
      bottom: 120px !important;
    }
  }
 @media (max-width: 500px) {
    .popover-container {
      width: calc(100% - 40px) !important;
      bottom: 90px !important;
      right: 18px;
      position: fixed !important;
    }
  }
  .popover-container iframe {
    border-radius: 24px;
    overflow: hidden;
    background-color: #fff;
  }
  .popover-container::after {
    content: "";
    position: absolute;
    border-radius: 34px;
    backdrop-filter: blur(4px);
    box-shadow: 0 0 20px 4px rgba(0, 0, 0, 0.15);
    z-index: -1;
    background-color: rgba(255, 255, 255, 0.5);
  }
  @media (min-width: 769px) {
    .popover-container::after {
      top: -20px;
      right: -20px;
      bottom: -20px;
      left: -20px;
    }
  }
  @media (max-width: 768px) {
    .popover-container::after {
      top: -12px;
      right: -12px;
      bottom: -12px;
      left: -12px;
    }
  }
.w-nav {
	z-index: 10 !important;
}
</style>
  </div>
  <div class="section wf-section">
    <div class="container w-container">
      <div class="w-layout-grid grid-2">
        <div id="w-node-_9a4d4125-b990-f626-2a13-0b31896612ad-57034985" data-w-id="9a4d4125-b990-f626-2a13-0b31896612ad" class="div-block-4">
          <h1 class="heading-2">über uns</h1>
          <h1 class="heading-4">Dunkin&#x27; Delivery</h1><img src="/images/webflow/coffee.png" srcset="/images/webflow/coffee-p-500.png 500w, /images/webflow/coffee.png 592w" width="280" height="400" sizes="(max-width: 479px) 100vw, (max-width: 767px) 96vw, 480px" alt="" class="image-5"><img src="/images/webflow/fly-donut.png" srcset="/images/webflow/fly-donut-p-500.png 500w, /images/webflow/fly-donut-p-800.png 800w, /images/webflow/fly-donut-p-1080.png 1080w, /images/webflow/fly-donut.png 1280w" height="" sizes="(max-width: 479px) 96vw, 100vw" alt="" class="image-7">
        </div>
        <div id="w-node-_8319be68-e2db-b021-fa0a-53dddd206763-57034985" data-w-id="8319be68-e2db-b021-fa0a-53dddd206763" class="div-block-5">
          <h1 class="heading-3">CUSTOM DONUTS</h1><img src="/images/webflow/donut.png" sizes="(max-width: 479px) 86vw, 410px" width="410" srcset="/images/webflow/donut-p-500.png 500w, /images/webflow/donut.png 500w" alt="" class="image-6">
        </div>
        <div id="w-node-_65e32da8-d559-7e04-7585-e8af7326ebd3-57034985" data-w-id="65e32da8-d559-7e04-7585-e8af7326ebd3" class="div-block-3">
          <h1 class="heading">order!</h1>
          <p class="paragraph-2">Triff eine Entscheidung:</p>
          <ul role="list" class="list">
            <li class="list-item">
              <a data-w-id="41d4d0c4-38a5-d75e-f55a-33cd62bc1453" href="#" class="order-btn w-button">Delivery</a>
            </li>
            <li class="list-item-2">
              <a data-w-id="3b2bf130-aee8-4789-4556-2ef2910a2a82" href="#" class="order-btn w-button">Click &amp; collect</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="div-block-6"></div>
  <script src="https://d3e54v103j8qbb.cloudfront.net/js/jquery-3.5.1.min.dc5e7f18c8.js?site=62fdf4af11a5b50023034984" type="text/javascript" integrity="sha256-9/aliU8dGd2tb6OSsuzixeV4y/faTqgFtohetphbbj0=" crossorigin="anonymous"></script>
  <script src="/js/webflow.dunkin.js" type="text/javascript"></script>
  <!-- [if lte IE 9]><script src="https://cdnjs.cloudflare.com/ajax/libs/placeholders/3.0.2/placeholders.min.js"></script><![endif] -->
</body>`;

export default DUNKIN;
